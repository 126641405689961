import React, { Component } from 'react';

import LayoutBuilder from '../components/layout-builder/layout-builder';
import Header from '../components/header/header';
import ImpressumSection from '../components/impressum-section/impressum-section';

const ImpresumSectionId = "impresum-section";
export default class Impressum extends Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <LayoutBuilder>
                <Header title="Impressum" scrollToId={ImpresumSectionId} />
                <ImpressumSection id={ImpresumSectionId} />
            </LayoutBuilder>
        )
    }
}