import React, { Component } from 'react';
import './impressum-section.css';
import { Section } from '../../interfaces/section-interface';

interface ImpressumSectionProps extends Section {
}

export default class ImpressumSection extends Component<ImpressumSectionProps, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className="default-section-background impressum-section-background" id={this.props.id}>
                <div className="default-section impressum-section" >
                    <h3>Angaben gemäß § 5 TMG</h3>
                    <p>
                        Artur Gräfenstein<br />
                        Lerchenweg 3<br />
                        34431 Marsberg
                    </p>
                    <h3 className="contact-header">Kontakt</h3>
                    <p>
                        Telefon:  <a className="link-without-decorator" href="tel:+4917695827227">+49 176 95827227</a><br />
                        E-Mail:  <a className="link-without-decorator" href="mailto:info@skirix.com">info@skirix.com</a><br />
                        <a href="/contact">Kontaktformular</a><br /><br /><br />
                        Copyright 2019 by Artur Gräfenstein
                    </p>
                    <h4 className="liability-header">Haftung für Inhalte</h4>
                    <p>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                        <br /><br />
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
                        Inhalte umgehend entfernen.
                    </p>
                    <h4>Haftung für Links</h4>
                    <p>
                        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                        übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    <br /><br />
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    </p>
                    <h4>Urheberrecht</h4>
                    <p>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
                        jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
                        dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    <br /><br />
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                        gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    </p>
                </div>
            </div >
        )
    }
}